import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Components
import Hero from '../content/Hero';
import Meta from '../../shared/Meta';
import CostEstimator from '../content/CostEstimator';
import Triple from '../content/Triple';
import VendorsToChooseFrom from '../VendorsToChooseFrom';
import CtaImage from '../content/CtaImage';
import LogoList from '../content/LogoList';
import AnyEvent from '../content/AnyEvent';

// Actions
import { clearServerRenderedPath } from '../../../actions/uiActions';

// Selectors
import { getCityConfig } from '../../../selectors';

// Misc
import IsoMorphUtils from '../../../modules/isoMorphUtils';
import { getUrl } from '../../../modules/helpers';
import styles from '../../../../stylesheets/public/layouts/AboutPage.module.postcss';
import tripleStyles from '../content/Triple.module.postcss';

class PizzaCatering extends Component {
  componentDidMount() {
    if (IsoMorphUtils.serverRendered(this.props)) {
      this.props.clearServerRenderedPath();
    }
  }

  render() {
    const { location, cityConfig } = this.props;

    const vehicleType = cityConfig.vehicle_type.toLowerCase();

    return (
      <div className="CateringPage">
        <Meta
          title={`Pizza Food ${cityConfig.vehicle_type} Catering - Weddings, Parties & Events - ${cityConfig.site_title}`}
          type="website"
          url={getUrl()}
          description={`Any party is a pizza party when you book ${cityConfig.city_name}'s best pizza food ${vehicleType}s for your event. Use our free catering request form below to receive bids from available food ${vehicleType}s in your area.`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Pizza Catering",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />

        <Hero photo="https://static.seattlefoodtruck.com/photos/PIZZA-header.jpeg">
          <h1>Pizza Food {cityConfig.vehicle_type} Catering</h1>
          <p>Any party is a pizza party when you book {cityConfig.city_name}'s best pizza food {vehicleType}s for your event. Use our free catering request form below to receive bids from available food {vehicleType}s in your area.</p>
          <a href="/catering/register" className="Button">
            Get started
          </a>
        </Hero>

        <section className="NewSection">
          <h3>How it works</h3>
          <div className={tripleStyles.Triple_wrapper}>
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering1.png"
              title="1. Answer a few questions"
              text={`Share details about your upcoming catering event using our free online request form and gain access to over 500 of ${cityConfig.city_name}'s best food {vehicleType}s.`}
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering2.png"
              title="2. Compare quotes"
              text="We’ll send you quotes from a selection of vendors that are available. You can message them directly to find out more about their food or drink offerings and coordinate the logistics of your event."
            />
            <Triple
              image="https://static.seattlefoodtruck.com/photos/catering3.png"
              title={`3. Book your ${vehicleType}`}
              text={`Choose your favorite food ${vehicleType}s and book directly with the vendor. There is no added commission of services fees, so you'll always know you get the best deal.`}
            />
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Pizza1.jpeg" imgClass="img-pizza1"/>
              <div className={styles.Cta_copy}>
                <h4>Slice to meet you</h4>
                <p>
                  Whether you want deep dish at your birthday party, wood-fired for your wedding,
                  or New York style for your company picnic, we’ve got you covered with some of the
                  best pizza food {vehicleType}s in {cityConfig.city_name} for you to choose from. These pizza {vehicleType}s can
                  customize menus for a group of 50 or 500 and keep lines moving fast! With a selection
                  of locally sourced ingredients, these food {vehicleType}s will serve your guest's delicious pizza
                  from a mobile oven on wheels. Choose from traditional to gourmet pies, vegetarian to meat
                  lovers. You can even let your guests build their own!
                </p>
                <div>
                <a href="/catering/register" className="Button">
                  Free Quote
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="PaddedSection--morePadding">
          <div className="Container">
            <div className={styles.Cta + " " + styles.Cta__reversed}>
              <CtaImage image="https://static.seattlefoodtruck.com/photos/Pizza2.jpeg" imgClass="img-pizza2"/>
              <div className={styles.Cta_copy}>
                <h4>Give pizza a chance</h4>
                <p>
                  Show some East Coast love with <Link to="/food-trucks/oskar-s-pizza">Oskar's Pizza</Link> food {vehicleType} and
                  their delicious foldable take on NY-style pizza. Prefer Neapolitan style? Wow your
                  guests with a wood-fired oven roaring in the background of your next event, slinging {cityConfig.city_name}'s best
                  pizza on wheels from vendors such as <Link to="/food-trucks/stella-fiore-wood-fired-pizza">Stella Fiore</Link> food {vehicleType}
                  or <Link to="/food-trucks/cascadia-pizza-co">Cascadia Pizza</Link> food {vehicleType}. Wanna try
                  Chicago style? {cityConfig.city_name}'s very own <Link to="/food-trucks/delfino-s-chicago-style-pizza">Delfino's Pizza</Link> food {vehicleType} offers
                  their take on the mid-west classic, serving an authentic 2” deep pizza’s made using fresh homemade
                  pizza dough and sauce.
                </p>
                <div>
                  <a href="/catering/register" className="Button">
                    Book a Food {cityConfig.vehicle_type}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <VendorsToChooseFrom options={{ food_categories: 'pizza' }} />

        <CostEstimator title="Pizza Catering Cost Estimator"/>

        <section className="NewSection">
          <LogoList title="Some of the companies we serve" />
        </section>

        <AnyEvent/>

        <section className="NewSection">
          <div className={styles.BookingCta}>
            <h3>Think of us for your next event</h3>
            <p>Free no-obligation estimates from {cityConfig.city_name}'s best food {vehicleType}s.</p>
            <a
              className="Button"
              href="/catering/register"
            >
              Food {cityConfig.vehicle_type} Catering
            </a>
          </div>
        </section>
      </div>
    );
  }
}

PizzaCatering.propTypes = {
  ui: PropTypes.object,
  clearServerRenderedPath: PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    ui: state.ui,
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearServerRenderedPath() {
      dispatch(clearServerRenderedPath());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PizzaCatering);
